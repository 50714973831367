<script>
import LoggerStatusIcon from '@/components/LoggerStatusIcon.vue';

export default {
  name: 'LoggerSelectPageList',
  components: {
    LoggerStatusIcon,
  },
  data() {
    return {
      page: 1,
      fields: [
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'serial',
          label: 'Serial',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          formatter: name => name || '(Not Set)',
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          formatter: desc => desc || '(Not Set)',
        },
        {
          key: 'last_update',
          label: 'Last Update',
          sortable: true,
          formatter: date => this.$d(date, 'short'),
        },
        {
          key: 'id',
          label: '',
        },
      ],
    };
  },
};
</script>

<template>
  <b-row>
    <b-col>
      <b-table v-bind:items="$store.getters['loggers/list']"
               :fields='fields'
               v-bind:currentPage='page'
               perPage='10'
               sort-icon-left
               responsive>

        <template v-slot:cell(status)="data">
          <logger-status-icon :status='data.item.status' :alarm='data.item.alarm' />
        </template>

        <template v-slot:cell(id)="data">
          <b-btn variant='primary' @click="$emit('view-logger', data.item.id)">View</b-btn>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>
