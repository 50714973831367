<script>
import LoggerSelectPageGrid from '@/components/LoggerSelectPageGrid.vue';
import LoggerSelectPageList from '@/components/LoggerSelectPageList.vue';
import LoggerSelectPageMap from '@/components/LoggerSelectPageMap.vue';

export default {
  name: 'LoggerSelectPage',
  components: {
    LoggerSelectPageGrid,
    LoggerSelectPageList,
    LoggerSelectPageMap,
  },
  computed: {
    noLoggers() {
      return this.$store.getters['loggers/listLength'] === 0;
    },
  },
  data() {
    return {
      loggerClaim: {
        error: null,
        serial: '',
        access_key: '',
      },
    };
  },
  beforeRouteEnter: (from, to, next) => {
    next((vm) => {
      vm.$store.dispatch('loggers/list')
        .finally(() => vm.$store.dispatch('ui/stopLoading'));
      next();
    });
  },
  created() {
    if (this.noLoggers) {
      this.$store.dispatch('ui/startLoading');
    }
  },
  mounted() {
    setTimeout(() => this.refresh(), 30000);
  },
  methods: {
    refresh() {
      this.$store.dispatch('loggers/list')
        .then(() => setTimeout(() => this.refresh(), 30000));
    },
    viewLogger(lid) {
      this.$Progress.start();
      this.$store.dispatch('loggers/get', lid)
        .then(() => this.$router.push(`/logger/${lid}`))
        .catch(() => {
          this.$Progress.fail();
        });
    },
  },
};
</script>

<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols=12>
          <b-card class='mt-2'>
            <!-- View Change Selector -->
            <div slot='header'>
              <b-button-group slot='header'>
                <b-button
                  variant='accent'
                  v-bind:pressed="$store.getters['ui/currentLoggerView'] === 'Grid'"
                  @click="$store.dispatch('ui/set_logger_view', 'Grid')"
                  v-bind:disabled='noLoggers'>
                  <fa-icon icon=th></fa-icon>
                </b-button>
                <b-button
                  variant='accent'
                  v-bind:pressed="$store.getters['ui/currentLoggerView'] === 'List'"
                  @click="$store.dispatch('ui/set_logger_view', 'List')"
                  v-bind:disabled='noLoggers'>
                  <fa-icon icon=list></fa-icon>
                </b-button>
                <b-button
                  variant='accent'
                  v-bind:pressed="$store.getters['ui/currentLoggerView'] === 'Map'"
                  @click="$store.dispatch('ui/set_logger_view', 'Map')"
                  v-bind:disabled='noLoggers'>
                  <fa-icon icon=map-marked></fa-icon>
                </b-button>
              </b-button-group>
              <b-btn variant='success'
                     v-b-modal.claim-logger
                     style='position: absolute; right: 20px' >
                <fa-icon icon='plus'></fa-icon>
              </b-btn>
            </div>

            <b-row v-if='noLoggers' align-h=center>
              <b-col cols='auto'>
                <h5 class='text-center'>There are no Loggers associated with your account.</h5>
              </b-col>
            </b-row>
            <b-row v-if='noLoggers' align-h='center' class='mt-1'>
              <b-col cols='auto'>
                <b-btn variant='success'  v-b-modal.claim-logger>
                  <fa-icon icon='plus'></fa-icon> Add Logger
                </b-btn>
              </b-col>
            </b-row>
            <!-- View Component is loaded as needed, then cached -->
            <keep-alive>
              <component v-bind:is="'LoggerSelectPage' + $store.getters['ui/currentLoggerView']"
                         v-on:view-logger='viewLogger' />
            </keep-alive>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id='claim-logger' title='Add Logger to Account' ref='claimModal' ok-only>
      <p>
        Logger Setup Instructions Here
      </p>
    </b-modal>
  </div>
</template>
