<script>
import LoggerStatusIcon from '@/components/LoggerStatusIcon.vue';

export default {
  name: 'LoggerSelectPageGrid',
  components: {
    LoggerStatusIcon,
  },
  methods: {
    /**
     * Check to see whether the last updated time is more than an hour
     */
    tooOld(date) {
      const now = new Date();
      if ((now - date) > 60 * 1000 * 60) { // 60s * 1000ms * 60 min
        return true;
      }
      return false;
    },
  },
};
</script>

<template>
    <b-row no-gutters>
      <!-- Template for each logger -->
      <b-col v-for="logger in $store.getters['loggers/list']"
             v-bind:key='logger.id'
             style='max-width: 160px;'
             cols=3 sm=3 md=3 lg=3 class='m-1'>
        <b-card body-class='card-grid' header-class='header-ellipsis'>
          <!-- Grid Item Header -->
          <span slot='header'>
            {{ logger.name || logger.serial }}
          </span>
          <!-- Grid item main body -->
          <b-container>
            <b-row align-h='center'>
              <!-- One status icon will be rendered -->
              <b-col cols='auto'>
                <logger-status-icon size='5x'
                                    :status='logger.status'
                                    :alarm='logger.alarm'
                                    textpos='bottom'/>
              </b-col>
              <!-- End of status icons -->
            </b-row>
            <!-- "View" button -->
            <b-row class='mt-1' no-gutters>
              <b-col cols=12>
                <b-button variant='primary' @click="$emit('view-logger', logger.id)" block>
                  View
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
</template>

<style scoped>
 .card-grid {
   padding: 0.8em;
 }

  .header-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
