<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LPopup,
  LFeatureGroup,
} from 'vue2-leaflet';

export default {
  name: 'LoggerSelectPageMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LFeatureGroup,
  },
  data() {
    return {
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 4,
      bounds: null,
      center: [48.3544, 99.9981],
      oldCenter: null,
      oldZoom: null,
    };
  },
  methods: {
    showAll() {
      this.$refs.loggerMap.mapObject
        .fitBounds(this.$refs.fg.mapObject.getBounds(), { padding: [50, 50] });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    resetMapAfterHover() {
      this.$refs.loggerMap.mapObject.setView(this.oldCenter, this.oldZoom);
      this.oldCenter = null;
      this.oldZoom = null;
    },
    zoomMapOnHover(point) {
      if (!this.oldCenter || !this.oldZoom) {
        this.oldCenter = this.center;
        this.oldZoom = this.zoom;
      }
      this.$refs.loggerMap.mapObject.setView(point.location, 12);
    },
  },
  computed: {
    mapPoints() {
      return this.$store.getters['loggers/list']
        .filter(logger => !!logger.location);
    },
    loggerCenter() {
      // If we actually have some locations, centre the map on them
      if (this.mapPoint && this.mapPoint.length > 0) {
        const sumX = this.mapPoints.reduce((prev, curr) => prev + curr.location[0], 0);
        const sumY = this.mapPoints.reduce((prev, curr) => prev + curr.location[1], 0);
        return [sumX / this.mapPoints.length, sumY / this.mapPoints.length];
      }

      // If we don't, centre the map on the USA
      return [39.828724, -98.579479];
    },
  },
  mounted() {
    this.center = this.loggerCenter;
    this.$nextTick(() => {
      this.showAll();
    });
  },
};
</script>

<template>
  <b-row>
    <b-col cols=12 md=8 class='mb-2'>
      <l-map
        ref="loggerMap"
        style="height: 500px; width: 100%"
        :zoom="zoom"
        v-bind:center="center"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated">
        <l-tile-layer :url='url' />
        <!-- Begin Map Marker Loop -->
        <l-feature-group ref='fg'>
          <l-marker
            v-for="logger in mapPoints"
            :key='logger.id'
            :visible=true
            :lat-lng='logger.location'
            >
            <l-popup>
              <p><b>{{ logger.name || logger.serial }}</b></p>
              <div class='text-center' v-if="logger.status === 'OFFLINE' && !logger.alarm">
                <fa-icon icon='minus-circle' :style="{ color: 'gray' }"/>
                Offline
              </div>
              <div v-else-if="logger.status === 'IDLE' && !logger.alarm">
                <fa-icon icon='check-circle' :style="{ color: 'black' }"/>
                Idle
              </div>
              <div v-else-if="logger.status === 'RECORDING' && !logger.alarm">
                <fa-icon icon='check-circle' :style="{ color: 'green' }"/>
                Recording
              </div>
              <div v-else-if="logger.alarm">
                <fa-icon icon='exclamation-circle' :style="{ color: 'red' }"/>
                Alarm
              </div>
            </l-popup>
            <l-tooltip :content='logger.name || logger.serial' />
          </l-marker>
        </l-feature-group>
        <!-- End Marker Loop-->
      </l-map>
    </b-col>
    <b-col cols=12 md=4>
      <b-card style='height: 100%; padding-bottom: 40px'>
        <!-- Individual Logger Cards -->
        <div v-if='mapPoints && mapPoints.length > 0' class='overflow-auto'>
          <b-card v-for='logger in mapPoints'
                  :key='logger.id' @mouseenter="zoomMapOnHover(logger)"
                  @mouseleave="resetMapAfterHover()"
                  class='mb-2'>
            <b-row>
              <b-col cols=8>
                <b-row>
                  <p class='lead'>
                    {{ logger.name || logger.serial }}
                  </p>
                </b-row>
                <b-row>
                  <div class='text-center ml-2'
                      v-if="logger.status === 'OFFLINE' && !logger.alarm">
                    <fa-icon icon='minus-circle' :style="{ color: 'gray' }"/>
                    Offline
                  </div>
                  <div class='text-center ml-2'
                      v-else-if="logger.status === 'IDLE' && !logger.alarm">
                    <fa-icon icon='check-circle' :style="{ color: 'gray' }"/>
                    Idle
                  </div>
                  <div class='text-center ml-2'
                      v-else-if="logger.status === 'RECORDING' && !logger.alarm">
                    <fa-icon icon='check-circle' :style="{ color: 'green' }"/>
                    Recording
                  </div>
                  <div class='text-center ml-2'
                      v-else-if="logger.alarm">
                    <fa-icon icon='exclamation-circle' :style="{ color: 'red' }"/>
                    Alarm
                  </div>
                </b-row>
              </b-col>
              <b-col cols=4 align-self='center'>
                <b-btn variant='primary' @click="$emit('view-logger', logger.id)">View</b-btn>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <!-- Div displayed when there are no loggers with locations -->
        <div v-else class='lead text-center'>
          No logger locations available
        </div>
        <!-- End of Individual Logger Cards -->
        <b-btn block
               variant='primary'
               @click='showAll()'
               style='position: absolute; bottom: 0; left: 0'>
          Show All
        </b-btn>
      </b-card>
    </b-col>
  </b-row>
</template>
